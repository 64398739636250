@import url('https://fonts.googleapis.com/css2?family=Georama:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{

  /* --- SESSÃO DE CORES --- */
  --primary-color: #7137c8;
  --secondary-color: #333;
  --tertiary-color: #FF8080;
  --quaternary-color: #FBB13C;

  --complementary-color: #9FC490;
  --special-color: #ffb400;
  --other-sec-color: #6A9BB2;

  --success-color: #2E8B57;
  --danger-color: #E95839;
  --warning-color: #FCC703;
  --edit-color: #1E90FF;
  --disabled-color: #7C7484;

  --pericia-color: #F15068;
  --reclamante-color: #51B476;
  --reclamada-color: #84b6f4;

  --text-color: #222;

  --background-color: #b0e0e6;
  --shadow-color: #B7B7B7;

  --module-size: 1rem;
  --default-padding: 6px;
  --default-border-radius: 6px;

}